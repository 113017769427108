import apiClient from '@/services/axios'

class TopService {
  async getSections() {
    const url = '/admin/top?full'
    return apiClient.get(url)
  }

  async createSection(data) {
    const url = '/admin/top?full'
    return apiClient.post(url, data)
  }

  async updateSection(id, data) {
    const url = '/admin/top/' + id + '?full'
    return apiClient.patch(url, data)
  }

  async deleteSection(id) {
    const url = '/admin/top/' + id
    return apiClient.delete(url)
  }

  async activeAllRecords(sectionId) {
    const url = '/admin/top/' + sectionId + '/activate-all?full'
    return apiClient.post(url, {})
  }

  async deleteInactiveRecords(sectionId, type) {
    // type: 'matches' or 'records'
    const url = '/admin/top/' + sectionId + '/delete-inactive?full&type=' + type
    return apiClient.delete(url)
  }

  async sortSections(sortData) {
    const url = '/admin/top/sort'
    return apiClient.patch(url, sortData)
  }

  async createRecord(sectionId, data) {
    const url = '/admin/top/' + sectionId + '/links'
    return apiClient.post(url, data)
  }

  async updateRecord(sectionId, recordId, data) {
    const url = '/admin/top/' + sectionId + '/links/' + recordId
    return apiClient.patch(url, data)
  }

  async sortRecords(sectionId, sortData) {
    const url = '/admin/top/' + sectionId + '/links/sort'
    return apiClient.patch(url, sortData)
  }

  async deleteRecord(sectionId, recordId) {
    const url = '/admin/top/' + sectionId + '/links/' + recordId
    return apiClient.delete(url)
  }

  async recordPutCover(sectionId, recordId, imgFile) {
    const url = '/admin/top/' + sectionId + '/links/' + recordId + '/cover'
    const formData = new FormData()
    formData.append('cover', imgFile)
    return apiClient.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async parseMatchUrl(matchUrl) {
    const url = '/admin/top/matches/check-url'
    const data = { url: matchUrl }
    return apiClient.post(url, data)
  }

  async createMatch(sectionId, data) {
    const url = '/admin/top/' + sectionId + '/matches/store-url'
    return apiClient.post(url, data)
  }

  async updateMatch(sectionId, recordId, data) {
    const url = '/admin/top/' + sectionId + '/matches/' + recordId
    return apiClient.patch(url, data)
  }

  async deleteMatch(sectionId, recordId) {
    const url = '/admin/top/' + sectionId + '/matches/' + recordId
    return apiClient.delete(url)
  }
}
export default new TopService()
